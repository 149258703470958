<template>
  <v-container>
    <v-row>
      <v-col :md="5" :cols="12">
        <v-card class="pa-5 ma-5" elevation="24">
          <h1 class="pb-5">Sign Up</h1>
          All new users get full access to all stocks for 24 hours
          <v-text-field
            outlined
            color="grey"
            v-model="email"
            ref="email"
            :rules="[rules.email, rules.required]"
            label="Email"
          ></v-text-field>
          <v-text-field
            outlined
            color="grey"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="showPassword ? 'text' : 'password'"
            label="Password"
            hint="At least 8 characters"
            v-model="password"
            class="input-group--focused"
            @click:append="showPassword = !showPassword"
          ></v-text-field>
          <v-btn large block color="" @click="signup()">Submit</v-btn>
          <p class="pt-5 pl-5 red--text" v-if="msg">{{ msg }}</p>
        </v-card>
      </v-col>
    </v-row>
    <v-overlay :value="overlay" opacity="0.8">
      <v-progress-circular
        v-if="!success"
        indeterminate
        size="64"
      ></v-progress-circular>
      <v-card
        class="title pa-5"
        color="grey lighten-2 black--text"
        v-if="success"
        >Success! Redirecting to login</v-card
      >
    </v-overlay>
  </v-container>
</template>
<script>
import { store } from "@/store/store";
import UserService from "../services/UserService";

export default {
  name: "Signup",
  components: {},
  data: () => ({
    store,
    success: false,
    status: "",
    complete: false,
    email: "",
    password: "",
    showPassword: false,
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 8 || "Min 8 characters",
      email: (v) => /.+@.+/.test(v) || "E-mail must be valid",
    },
    msg: "",
    overlay: false,
  }),
  mounted() {
    // this.$nextTick(() => {
    //   this.$refs.email.focus()
    // })
  },
  computed: {},
  methods: {
    async signup() {
      this.overlay = true;
      UserService.attemptSignup(this.email, this.password)
        .then((response) => {
          this.success = true;
          setTimeout(() => {
            this.store.user = { email: this.email };
            this.$router.push("/login");
          }, 3000);
        })
        .catch((err) => {
          this.overlay = false;
          this.msg = err.response.data.response;
        });
    },
  },
};
</script>
